.section {
  margin: 0;
  color: #010203;
  // font: 600 16px/18px "Open Sans", sans-serif;
  background: url("../../../assets/images/imageonline-co-overlayed-image.png")
    no-repeat center;
  // background: url("../assets/images/imageonline-co-overlayed-image.png") no-repeat center;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
}

.login-vector {
  background: url("../../../assets/images/undraw_investment_xv9d.png");
  background-size: cover;
  height: 380px;
  width: 100%;
  border-bottom: 2px solid #cecece;
  position: relative;
}

.absolute-title {
  position: absolute;
  transform: translate(22.6rem, 0.5rem);
}

.title-1,
.title-2 {
  font-family: "CodaCaption";
  color: #3f3d56;
}

.title-1 {
  font-size: 5rem;
}

.title-2 {
  font-size: 3rem;
}

.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

.login-wrap {
  width: 100%;
  margin: auto;
  max-width: 525px;
  min-height: 670px;
  position: relative;
  background: transparent;
  border-radius: 1.35rem;
  box-shadow: 7px 9px 20px #08233f, -7px -9px 20px #123b61;
  overflow: hidden;
}

.login-html {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 10px 50px;
  /* background: linear-gradient(-45deg, rgba(87, 175, 246, 0.9), rgba(227, 225, 243, 0.9)); */
  background: url("../../../assets/images/white-concrete-wall_53876-92803.jpg")
    center no-repeat;
  background-size: cover;
}

.login-html .sign-in-htm,
.login-html .sign-up-htm {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  transition: all 0.4s linear;
}

.login-html .sign-in,
.login-html .sign-up,
.login-form .group .check {
  display: none;
}

.login-html .tab,
.login-form .group .label {
  text-transform: uppercase;
  text-align: left;
}

.login-form .group .button {
  text-transform: uppercase;
}

.login-html .tab {
  font-size: 22px;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-weight: 700;
  display: block;
  text-align: center;
  margin-right: 15px;
  padding-bottom: 5px;
  margin: 0 15px 10px 0;
  border-bottom: 2px solid transparent;
  color: #3f3d56;
}

.login-html .sign-in:checked + .tab,
.login-html .sign-up:checked + .tab {
  color: #fff;
  border-color: #1161ee;
}

.login-form {
  min-height: 345px;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.login-form .group {
  margin-bottom: 15px;
}

.login-form .group .label,
.login-form .group .input,
.login-form .group .button {
  width: 100%;
  display: block;
}

.login-form .group .input,
.login-form .group .button {
  /* border: none;
    padding: 10px 15px;
    border-radius: 15px;
    background: rgba(54, 177, 248, 0.116);
    outline: none; */
  padding: 7px 12px;
  border-radius: 0.3rem;
  box-shadow: 2px 2px 3px #25269465, 0px 0px 2px #25269465;
}

.login-form .group input[data-type="password"] {
  -webkit-text-security: circle;
}

.login-form .group .label {
  color: #3f3d56;
  font-size: 12px;
}

.login-form .group .button {
  border-radius: 0.35rem;
  padding: 10px 17px;
  background: #3f3d56;
  color: white;
}

.login-form .group label .icon {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  position: relative;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
}

.login-form .group label .icon:before,
.login-form .group label .icon:after {
  content: "";
  width: 10px;
  height: 2px;
  background: #fff;
  position: absolute;
  transition: all 0.2s ease-in-out 0s;
}

.login-form .group label .icon:before {
  left: 3px;
  width: 5px;
  bottom: 6px;
  transform: scale(0) rotate(0);
}

.login-form .group label .icon:after {
  top: 6px;
  right: 0;
  transform: scale(0) rotate(0);
}

.login-form .group .check:checked + label {
  color: #fff;
}

.login-form .group .check:checked + label .icon {
  background: #1161ee;
}

.login-form .group .check:checked + label .icon:before {
  transform: scale(1) rotate(45deg);
}

.login-form .group .check:checked + label .icon:after {
  transform: scale(1) rotate(-45deg);
}

.login-html
  .sign-in:checked
  + .tab
  + .sign-up
  + .tab
  + .login-form
  .sign-in-htm {
  transform: rotate(0);
}

.login-html .sign-up:checked + .tab + .login-form .sign-up-htm {
  transform: rotate(0);
}

.hr {
  height: 2px;
  margin: 60px 0 50px 0;
  background: rgba(5, 1, 1, 0.2);
}

.foot-lnk {
  text-align: center;
}

.errorMessage {
  font-size: 11px;
  color: rgb(235, 28, 62);
}
