.selected-user-container {
  border-radius: var(--radius-50);
  overflow: hidden;
  margin: var(--margin-big);
  border: var(--border-primary-thin);
  background: var(--bg-secondary-header);
  .info-header {
    font-weight: 800;
    padding: var(--padding-big);
    font-size: 1.2rem;
    border-bottom: var(--border-primary-thin);
    background: var(--card-header-primary);

    .username {
      text-transform: capitalize;
      font-size: 2rem;
    }
  }

  .info-body {
    display: flex;
    justify-content: space-between;

    .info-body-col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 35vh;
      width: 100%;
      align-items: stretch;

      .info-data {
        font-size: 1.25rem;
        margin: var(--padding-regular) 0;

        span:first-child {
          font-weight: 800;
          color: rgb(214, 214, 214);
        }

        span:last-child {
          font-weight: 700;
        }
      }

      .info-body-footer {
        border-top: var(--border-primary-thin);
        padding: var(--padding-regular);
        background: var(--bg-primary-header);
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        .footer-info {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .roles-col {
      min-width: 20rem;
      border-right: var(--border-primary-thin);

      .header {
        background: var(--bg-primary-header);
        padding: var(--padding-medium);
        font-weight: 800;
      }

      .role-container {
        .role-key {
          display: block;
          font-size: 1.1rem;
          padding: var(--padding-regular);
          font-weight: 800;
        }

        .role-desc {
          font-size: 0.95rem;
          color: rgb(209, 209, 209);
          padding-left: 0.5rem;
        }
      }
    }
  }
}

.data-info {
  position: relative;
  .user-status {
    position: absolute;
    top: 10%;
    color: rgba(128, 128, 128, 0.37);
    font-weight: 600;
    right: 25%;
    font-size: 5rem;
    transform: translate(100%, 65%) rotate(320deg);
    user-select: none;
  }
}
