.race-form-container {
  display: flex;

  .track-form {
    width: 25%;
    height: 80vh;
    border-right: var(--border-primary-thin);
    display: flex;
  }

  .race-form {
    height: inherit;
    // padding: var(--padding-medium);
    width: 75%;

    .race-details {
      .trackName {
        padding: 1rem;
        display: flex;
        font-size: 2rem;
        align-items: center;
        font-weight: 600;
        border-bottom: var(--border-primary-thin);

        .event-icon {
          padding: 1rem;
        }
      }
    }
  }
}

.country-container {
  display: flex;
  overflow: auto;
  flex-direction: column;
  width: 15%;
  border-right: var(--border-primary-thin);
  height: inherit;

  button {
    font-weight: 600;
    font-size: 1.2rem;
    border: none;
    padding: 0.5rem 0;
    background: transparent;
    border-bottom: var(--border-primary-thin);
    transition: 0.3s;

    &:hover {
      background: var(--btn-hover-priamry);
    }

    &.active {
      background: var(--btn-active);
    }
  }
}

.track-name-container {
  width: 100%;
  height: inherit;

  .tracks {
    height: calc(100vh - 25vh);
    overflow: auto;

    button {
      border: none;
      border-bottom: var(--border-primary-thin);
      padding: var(--padding-regular) var(--padding-medium);
      text-align: left;
      background: transparent;
      width: 100%;
      transition: 0.3s;
      display: flex;
      justify-content: space-between;
      font-size: 1.1rem;

      &:hover {
        background: var(--btn-hover-priamry);
      }

      &.active {
        background: var(--btn-hover-priamry);
      }
    }
  }

  .search-input {
    padding: var(--padding-regular);
    width: 100%;
    border-bottom: var(--border-primary-thin);
    box-shadow: 0 1px 3px #25252571;

    input {
      padding: var(--padding-regular) var(--padding-medium);
      border: none;
      outline: none;
      background: var(--bg-input-primary);
      border-radius: var(--radius-35);
      width: inherit;
      border: var(--border-primary-thin);
    }
  }
}

// .pool-sidebar {
//   position: absolute;
//   top: 0;
//   height: 100%;
//   right: 0;
//   width: 30%;
//   z-index: 300;
//   background: var(--bg-primary-header);
// }

//   padding-bottom: 7rem;
.float-btn {
  position: fixed;
  bottom: 4rem;
  right: 2rem;
  z-index: 101;
  border-radius: 2rem;
  overflow: hidden;

  button {
    background: var(--btn-primary);
    // border-radius: 2em !important;
    box-shadow: 2px 3px 9px #363636, 1px 0px 11px 0px #363636;
    color: #ffffff;
    font-weight: 700;
    font-size: 1.2rem;
    padding: 10px 40px 10px 40px;
    border: solid #3f3f3f 1px;
  }
}
.details {
  padding: var(--padding-regular);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #424141;
  .trackName {
    font-size: 1.6rem;
    font-weight: 800;
  }
}

.wrapper .icon {
  position: relative;
  background-color: #883cae;
  border-radius: 25%;
  margin: 5px;
  width: 35px;
  height: 35px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
  position: absolute;
  background-color: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background-color: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background-color: #61239b;
  color: #ffffff;
}