.footer-container {
  position: relative;
  z-index: 5;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  background: var(--bg-primary-header);
  color: var(--text-primary);
  padding: 10px;
  height: var(--height-footer);
  box-shadow: -2px 0px 3px rgb(134, 134, 134);
}
