.pz-overlaypanel {
  .p-overlaypanel-content {
    color: #fff !important;
  }
  .overlay_update_btns {
    background: #883cae;
    border-radius: 5px;
    padding: 8px 20px;
    border: none;
    font-weight: 600;
    box-shadow: -1px 1px 4px #313234;
  }
  .overlay_cancel_btns {
    background: #f772a3;
    border-radius: 5px;
    padding: 8px 20px;
    border: none;
    font-weight: 600;
    box-shadow: -1px 1px 4px #313234;
  }
}
.edit_race_body {
  display: flex;
  flex-direction: column;
  .p-inputtext {
    color: #fff !important;
    padding: 1rem 0.75rem !important;
    background: #262626 !important;
    border: none;
    margin-bottom: 10px !important;
  }
}
.overlay_panel_heading {
  border-bottom: 1px solid #fff;
  margin-bottom: 5px !important;
  h3 {
    margin-bottom: 5px !important;
  }
}
.race-time-wrapper {
  width: auto;
  height: 100%;
  color: #fff;
  .heading {
    padding: 0px 8px 8px 0px;
    // border-bottom: 1px solid #fff;
    .title {
      font-size: 20px;
      color: #fff;
      text-transform: capitalize;
    }
  }
  .p-datatable .p-datatable-tbody > tr > td {
    background: #2b2a2a !important;
  }
  .p-datatable .p-datatable-thead > tr > th {
    border-width: 1px 0 1px 0;
  }
  .p-sidebar {
    z-index: 100;
    background: #353535 !important;
  }
  .p-sidebar-left {
    width: 50% !important;
  }
  .edit_race_body {
    padding: 10px 5px;
  }
  .empty_data {
    display: flex;
    color: #fff;
    font-size: 25px;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .race-time-body {
    width: 100%;
    height: 100%;
    color: #fff;
  }
  .round_btn {
    background: transparent;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 0;
    margin-right: 10px;
    border: none;
    transition: 0.3s ease-in-out;
    &:hover {
      background: #883caea1 !important;
    }
  }
}
