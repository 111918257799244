.open-btn-race {
  position: fixed;
  bottom: 5rem;
  right: 5rem;
  box-shadow: 0px 0px 10px #28292849;
  border: 2px solid rgb(172, 40, 212);
  background-color: rgba(53, 9, 70, 0.432);
  color: white;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  z-index: 17;
  font-size: 1.5rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #37225f; // linear-gradient(45deg, #37225f, #274d79);
  }
}

.providers-btn {
  background: var(--table-header-dark);
  box-shadow: inset -1px -2px 6px #212121ab, 3px 2px 6px #3836369c;
  margin: 0 1rem 0 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.3s;
  &:hover {
    background: #4fa1ff99;
    cursor: pointer;
  }
}
.empty-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #3e3e3e;
  flex-direction: column;
}
.poolListWrapper {
  border-radius: 0.5rem;
  flex-grow: 1;
  margin-right: 1rem;
  overflow: hidden;
  border: thin solid #2f2e3a;
  .poolHeader {
    background: #2f2e3a;
    font-weight: 600;
    min-width: 8rem;
    padding: 0.25rem 1rem;
    border-bottom: var(--border-primary-thin);
  }
  .poolBtnContainer {
    background: #2c2e30;
    display: flex;
    flex-wrap: wrap;
    .tab-btn {
      background: transparent;
      border: none;
      border-right: var(--border-primary-thin);
      padding: var(--padding-regular) var(--padding-medium);
      min-width: 110px;

      &.active {
        background: var(--btn-active);
      }

      &:hover {
        background: var(--btn-hover-priamry);
      }
    }
  }
}
.poolStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.1rem;
  min-width: 50px;

  &.open {
    background: var(--status-open);
  }

  &.closed {
    background: var(--status-closed);
  }

  &.resulted {
    background: var(--status-resulted);
  }

  &.non_display {
    background: #585656;
  }
}
