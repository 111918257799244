.user-cards-container {
  padding: 0.2rem;
  width: 30rem;
  .user-card {
    overflow: hidden;
    position: relative;
    border-radius: var(--radius-35);
    border: var(--border-primary-thin);
    margin: 0.5rem 0.25rem;
    font-weight: 700;
    transition: 0.3s;
    background: var(--card-primary);
    display: flex;
    justify-content: space-between;
    .email {
      font-weight: 800;
      color: rgb(199, 199, 199);
      padding: 0.5rem;
    }
    .username {
      display: block;
      font-size: 1.35rem;
      padding: 0.5rem;
      text-transform: capitalize;
    }

    &:hover {
      background: var(--btn-hover-priamry);
      cursor: pointer;
      border: var(--border-focus-thin);
    }

    &.active {
      background-color: var(--color-primary);
    }
  }
}
.content-not-found{
  font-size: 15px;
  font-family: 'poppins', sans-serif;
}

.sidebar-tab {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: var(--border-primary-thin);

  button {
    flex-grow: 1;
    background: #2e2e2d !important;
    border-radius: 0 !important;
    transition: 0.3s;
    &:first-child {
      border-right: var(--border-primary-thin);
    }

    &:hover {
      background: var(--btn-hover-priamry) !important;
      cursor: pointer;
    }
  }
}

.head {
  background: var(--bg-primary-header);
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  width: 100%;
}

.deleted-badge {
  background: #0000001c;
  transform: rotate(45deg);
  position: absolute;
  top: -30px;
  right: -30px;
  height: 5rem;
  width: 5rem;
}

.delete-icon,
.suspend-icon {
  background: #0000001c;
  padding: 0.65rem;
  border-radius: 0.5rem;
  margin: 0.25rem;
}
.delete-icon {
  color: var(--text-danger);
}
.suspend-icon {
  color: var(--color-orange);
}

.src-container {
  position: relative;
  width: 50%;

  input.src-input {
    background: rgb(53, 53, 53);
    width: 100%;
    outline: none;
    padding: 0.75rem;
    border: var(--border-primary-thin);
    transition: 0.3s;

    &:focus {
      border: var(--border-focus-thin);
    }
  }

  .src-icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    color: rgb(197, 197, 197) /*  */;
  }
}
