.form {
  background: var(--card-primary);
  height: calc(100vh - 12.5vh);
  width: 70%;
  overflow-y: auto;
}
.button-style {
  border-radius: var(--radius-35);
  border: none;
  box-shadow: 0 0 3px var(--color-primary);
  padding: 0.5rem 1rem;
  font-weight: 700;
  transition: 0.3s;
  background-color: #a06db9;
}
.button-style-selected {
  border-radius: var(--radius-35);
  border: none;
  box-shadow: 0 0 3px var(--color-primary);
  padding: 0.5rem 1rem;
  font-weight: 700;
  transition: 0.3s;
  color: white;
  background-color: #883cae;
}
.input-container-add {
  margin: 1rem 0;
  padding: 0 0.5rem;
  .input {
    position: relative;
    input {
      outline: none;
      padding: var(--padding-medium);
      border-radius: var(--radius-35);
      width: 100%;
      background: var(--bg-input-primary);
      border: var(--border-primary-thin);
      transition: 0.3s;
      color: white;
    }
  }
}
.btn-func {
  display: flex;
  align-self: center;
  border: none;
  background: #883cae;
  margin: 2px;
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 30px;
  // width: 30px;
}
.contain_filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background: #303030;
  margin: 5px;
}
.inContain {
  padding: 1px;
}

