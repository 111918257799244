.bet_history_wrapper {
  width: 100%;
  height: 100%;
  .page-nav {
    display: flex;
    align-items: center;
    width: 80%;
    padding: 8px;
    flex-wrap: wrap;
    .action_bx {
      margin: 5px;
    }
    .betopt {
      min-width: 180px;
    }
  }
  .sidebar {
    width: 25rem;
  }
  .csv_btn {
    display: flex;
    align-items: center;
    padding-right: 8px;
  }
  .pool_payout_dailog {
    width: 30vw;
    .table-class .p-datatable-thead > tr > th,
    .table-class .p-datatable-thead > tr,
    th,
    td {
      background: #2a2a2a !important;
      color: #fff !important;
      border-width: 1px 0 1px 0 !important;
    }
    .footer_btn {
      border-radius: 5px !important;
    }
  }
  .no_data {
    display: flex;
    justify-content: center;
    font-size: 20px;
    padding: 10px;
  }
}
