.dash-body {
  display: flex;
  flex-direction: column;
  height: var(--content-height);
}

.second-half {
  margin-top: auto;
  .active-race-container {
    .a-race-filter {
      background: #3c3c3c;
      border-bottom: var(--border-primary-thin);
      display: flex;
      justify-content: space-between;
    }
  }
}

.a-race-grid {
  display: flex;
  overflow-y: auto;
  overflow-x: auto;
  background: #3c3c3c;
  .a-race-card {
    border-radius: 0.75rem;
    overflow: hidden;
    min-width: 300px;
    margin: 0.5rem;
    background: #242424;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 5px #212222, inset -3px -2px 5px #1b201b;
    .arc-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      background: #323232;
    }

    .arc-body {
      background: #404040;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      .pools-container {
        border-top: thin solid #171717;
        transition: 0.3s;
        
        .pools-header {
          background: #252525;
          padding: 0.5rem 1rem;
          font-weight: 600;
        }

        .pools-body {
          display: flex;
          .pools-tab {
            padding: 0.5rem 0;
            flex-grow: 1;
            &:not(:last-child) {
              border-right: thin solid #171717;
            }

            .p-h {
              font-style: italic;
              font-size: 0.85rem;
              font-weight: 600;
              color: gray;
              text-align: center;
            }
            .p-b {
              //   padding: 0.5rem 0;
              text-align: center;
              font-weight: 600;
            }
          }
        }
      }
    }
    .arc-footer {
      background: #323232;
      margin-top: auto;
      display: flex;
      align-items: center;
      padding: 0.5rem;
    }
  }
}

// .race-card-grid {
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   row-gap: 1.5rem;
//   column-gap: 1.5rem;
//   padding: 1rem;
//   .a-race-card {
//     border-radius: 0.75rem;
//     overflow: hidden;
//     background: #242424;
//     display: flex;
//     flex-direction: column;
//     box-shadow: 2px 2px 5px #212222, inset -3px -2px 5px #1b201b;
//     .arc-head {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       padding: 1rem;
//       background: #171717;
//     }

//     .arc-body {
//       background: #242424;
//       height: 100%;
//       display: flex;
//       flex-direction: column;
//       align-items: stretch;
//       .pools-container {
//         border-top: thin solid #171717;
//         transition: 0.3s;

//         .pools-header {
//           background: #171717;
//           padding: 0.5rem 1rem;
//           font-weight: 600;
//         }

//         .pools-body {
//           display: flex;
//           .pools-tab {
//             padding: 0.5rem 0;
//             flex-grow: 1;
//             &:not(:last-child) {
//               border-right: thin solid #171717;
//             }

//             .p-h {
//               font-style: italic;
//               font-size: 0.85rem;
//               font-weight: 600;
//               color: gray;
//               text-align: center;
//             }
//             .p-b {
//               //   padding: 0.5rem 0;
//               text-align: center;
//               font-weight: 600;
//             }
//           }
//         }
//       }
//     }

//     .arc-footer {
//       background: #171717;
//       margin-top: auto;
//       display: flex;
//       align-items: center;
//       padding: 0.5rem;
//     }
//   }
// }

.event-img {
  height: 2rem;
  width: 3.3rem;
}

.name {
  font-size: 1.3rem;
  padding: 0 1rem;
  font-weight: 600;
}

// .data-filter {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 0.5rem;
//   background: #050505;
//   box-shadow: -1px 3px 5px rgb(29, 28, 28);
// }

.op-btn {
  font-size: 1.2rem;
  margin-left: auto;
  height: 30px;
  border: none;
  background: transparent;
  width: 30px;
  border-radius: 50%;
  transition: 0.3s;
  &:hover {
    background: white;
    color: black;
  }
}
