.selected-Track-Race-container {
  margin: var(--margin-medium);
  border-radius: var(--radius-50);
  background-color: var(--card-header-primary);
  // overflow: hidden;
  display: flex;
  overflow-x: auto;
  border: 1px solid #484848;
  padding: 5px;
}

.track-race-card {
  margin: 0.25rem 0.5rem;
  border-radius: 0.35rem;
  background: linear-gradient(162deg, #444141, #585858);
  transition: 0.3s;
  border: 2px solid rgba(0, 0, 0, 0);
  box-shadow: 0 0 2px #30323ca3, inset 0 0 2px #696969;
  min-width: 270px;
  min-height: 6rem;
  .postTime {
    flex-shrink: 0;
  }
  .d-row {
    display: flex;
    justify-content: space-between;
    padding-left: 0.5rem;
    height: 100%;
    .trackName {
      font-size: 1.2rem;
      font-weight: 700;
      padding: 0.5rem;
      padding: 0.5rem 0;
    }
  }
  &:hover {
    background: #585858;
    cursor: pointer;
  }
}

.cancelled_badge {
  margin-right: 0.5rem !important;
  font-size: 12px !important;
  text-transform: uppercase;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background: #dc3545;
  padding: 2px 2px;
  font-weight: 600;
}

.active-track-race-card {
  margin: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  // box-shadow: inset 0px -2px 8px #100f0f, 4px 1px 6px #242412;
  overflow: hidden;
  // background: radial-gradient(#574e5ebd, #883cae75);
  background: var(--btn-hover-priamry);
  border: 2px solid rgba(0, 0, 0, 0);
  min-width: 270px;
  cursor: pointer;
  min-height: 6rem;

  .postTime {
    flex-shrink: 0;
  }
  .d-row {
    display: flex;
    justify-content: space-between;
    padding-left: 0.5rem;

    height: 100%;

    .trackName {
      font-size: 1.2rem;
      font-weight: 700;
      padding: 0.5rem 0;
    }
  }
  // &:hover {
  //   background: #585858;
  // }
}
