.po-data {
    margin: 0.5rem 0;
    display: flex;
    border-radius: 0.5rem;
    border: 1px solid #e9e9e9;
    box-shadow: 2px 2px 6px #54545454;
    overflow: hidden;
    .selId {
      font-size: 1.5rem;
      font-weight: 600;
      padding: 1rem;
      background: #e9e9e9;
    }
  }
  
  .po-table {
    width: 100%;
  
    table {
      border-radius: 0.5rem;
      overflow: hidden;
      width: 100%;
      tr {
        th {
          background: #3d3d3d;
        }
        td {
          background: #656565;
        }
  
        td,
        th {
          border: thin solid #3d3d3d88;
          padding: 0.5rem;
        }
      }
    }
  }