.selected-event-container {
  margin: var(--margin-medium);
  border-radius: var(--radius-50);
  background-color: var(--card-header-primary);
  overflow: hidden;

  .selected-event-header {
    display: flex;
    min-height: 5rem;
    position: relative;

    .event-type-img {
      display: flex;
      padding: var(--padding-big);
      align-items: center;
      justify-content: center;
      min-width: 5rem;
    }

    .event-details {
      // padding: var(--padding-regular);
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .detail-container {
        display: flex;
        height: 100%;

        .trackName {
          font-size: 1.5rem;
          font-weight: 800;
        }

        &.col-between {
          justify-content: space-between;
          flex-direction: column;
        }

        &.end {
          align-items: flex-end;
        }

        img {
          height: 1.8rem;
          width: 1.8rem;
          margin: 0 0.5rem;
        }

        .results > span {
          display: inline-block;
          font-weight: 600;
          color: #0e0e0e;
          background: #ffc800;
          border-radius: 50%;
          padding: 0.25rem 0.7rem;
          margin: 0 0.25rem;
        }
      }
    }
  }
}
.sts {
  border-bottom-left-radius: 50%;
  width: 4.5rem;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: 900;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: -4px 2px 18px #1b2127;

  &.open {
    background: var(--status-open);
  }

  &.closed {
    background: var(--status-closed);
  }

  &.resulted {
    background: var(--status-resulted);
  }
}
.canceled_badge {
  border-bottom-left-radius: 1rem;
  width: 6.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 900;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: -4px 2px 18px #1b2127;
  background: #dc3545;
}

.event-detail-tab {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  overflow: auto;

  .tab-item {
    border-right: var(--border-primary-thin);
    padding: 0.5rem;
    text-align: center;
    flex-grow: 1;
    border-bottom: var(--border-primary-thin);
    min-width: 150px;
  }
}
.input-container .p-chips-multiple-container.p-focus {
  box-shadow: none !important;
  border: var(--border-focus-thin) !important;
}
.input-container .p-chips-multiple-container {
  background: var(--bg-input-primary) !important;
  border: var(--border-primary-thin) !important;
}
.input-container .p-chips-multiple-container .p-chips-input-token input {
  color: var(--text-primary) !important;
  max-width: 80px;
}
.skipFields {
  background: #464646 !important;
  border: 1px solid #565656 !important;
  width: 95% !important;
  .p-multiselect-trigger {
    background: #343434 !important;
    color: #ffffff !important;
  }
}
.result-label {
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
}
.race-dialog-box {
  .p-dialog-header {
    background: #414141 !important;
    color: #ffffff !important;
  }
  .p-dialog-content {
    background: #707070fa !important;
    color: #ffffff !important;
  }
  .p-dialog-footer {
    background: #414141 !important;
    color: #ffffff !important;
  }
  .p-field-checkbox {
    margin: 6px 0px !important;
    padding: 10px 16px !important;
    display: flex !important;
    width: 100% !important;
    label {
      cursor: pointer;
      margin-bottom: 0px !important;
      width: 100% !important;
    }
  }
}
.closed_race_status {
  font-size: 13px;
  text-transform: uppercase;
  color: #ff6161;
  font-weight: 700;
}
.badge {
  font-size: 11px !important;
  padding: 5px !important;
  margin-top: 11px;
  margin-left: 12px;
}
.p-field-checkbox:hover {
  background: #ba5aeb80;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.action-btns .pz-btn {
  padding: 0.75rem 1.5rem;
}
.p-dialog {
  min-width: 16vw !important;
}
.p-dialog .p-dialog-footer,
.p-dialog-header {
  border: none !important;
  .p-dialog-header-close {
    color: #fff !important;
    border: 1px solid #fff !important;
  }
}
