.wdt-25 {
  width: 25rem;
}
.wdt-30 {
  width: 30rem;
}

.error-container {
  padding: var(--padding-regular) var(--padding-big);
  color: var(--text-danger);
  font-weight: 600;
}

.warning-container {
  padding: var(--padding-regular) var(--padding-big);
  color: var(--text-note);
  font-weight: 600;
}

.pz-prime-table {
  border-radius: 0.5rem;
  overflow: hidden;
  border: var(--border-primary-thin);

  .p-datatable-thead > tr > th {
    background: var(--table-header-dark) !important;
    color: var(--text-primary) !important;
    border: none !important;
    border-bottom: 2px solid gray !important;
  }

  .p-datatable-tbody > tr > td {
    background: var(--table-body-dark) !important;
    color: var(--text-primary) !important;
    border-bottom: var(--border-primary-thin) !important;
  }

  .p-datatable-header {
    background: var(--table-header-dark) !important;
  }

  .p-paginator-bottom {
    background: var(--table-header-dark) !important;
  }
}
.form-container {
  margin: var(--margin-medium);
  border-radius: var(--radius-35);
  overflow: hidden;
  display: inline-block;
  width: 50%;
  background: var(--bg-secondary-header);
  .form-header {
    padding: var(--padding-medium);
    font-size: 1.5rem;
    font-weight: 800;
    border-bottom: var(--border-primary-thin);
    background: var(--bg-primary-header);
  }

  .form-body {
    // display: flex;
    padding: var(--padding-medium);
  }

  .form-footer {
    padding: var(--padding-medium) var(--padding-big);
    border-top: var(--border-primary-thin);
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.App-container {
  background: var(--bg-primary);
  position: relative;
}

.active-btn {
  background: var(--btn-active) !important;
}

.label {
  color: rgb(228, 228, 228);
  font-size: 12px;
  font-style: italic;
}
.search-input {
  position: relative;

  input {
    width: 100%;
    border-radius: 0.5rem;
    outline: none;
    background: var(--bg-input-primary);
    padding: var(--padding-regular) var(--padding-medium);
    border: var(--border-primary-thin);
    transition: 0.3s;

    &:focus {
      border: var(--border-focus-thin);
    }
  }

  .icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    color: rgb(197, 197, 197) /*  */;
  }
}

.content {
  display: flex;
  color: var(--text-primary);
  position: relative;
  &.heightfull {
    height: calc(100vh - (var(--height-header) + var(--height-footer)));
  }

  &.heightmenu {
    height: calc(
      100vh - (var(--height-header) + var(--height-footer) + var(--height-menu))
    );
  }

  .panel-btns {
    display: flex;
    flex-direction: column;

    button {
      border: none;
      background: transparent;
      font-weight: 600;
      text-align: left;
      padding: 1rem;
      transition: 0.3s;

      &:hover {
        background: var(--btn-hover-priamry);
      }
    }
  }

  // Panels
  .content-left-panel {
    // width: 30%;
    overflow-y: auto;
    position: relative;
    background: var(--sidebar-dark);
    border-right: var(--border-primary-thin);
  }

  .content-middle-panel {
    overflow: auto;
  }

  .content-right-panel {
    transition: 0.3s ease-in-out;
    border-left: var(--border-primary-thin);
    background: var(--sidebar-dark);

    &.open {
      width: 20%;
    }

    &.closed {
      width: 2.5%;
    }
  }

  // Panel Header
  .content-panel-header {
    border-bottom: var(--border-primary-thin);
    padding: var(--padding-regular);
    display: flex;
    font-weight: 600;
    align-items: center;

    &.single {
      justify-content: center;
    }

    &.multiple {
      justify-content: space-between;
    }
  }
}

.pz-btn {
  border-radius: var(--radius-35);
  border: none;
  box-shadow: 0 0 3px var(--color-primary);
  padding: 0.75rem 2.5rem;
  font-weight: 700;
  transition: 0.3s;

  &.primary {
    background: var(--btn-primary);
    color: var(--text-primary);
    &:hover {
      background: var(--btn-hover-primary-dark);
    }
  }
  &.rounded {
    border-radius: 2em !important;
  }
  &.danger-btn {
    background-color: var(--color-red);
  }
  &.warning {
    background: var(--color-orange);
    color: var(--color-primary);
  }
  &.text-btn {
    background: transparent;
    box-shadow: none;
    &.text-d:hover {
      color: var(--color-red);
    }
  }

  &.icon-btn {
    padding: 0.2rem 0.5rem;
    box-shadow: none;
    background: transparent;

    &.hover:hover {
      background: var(--btn-hover-priamry);
    }

    &:disabled {
      color: gray;
    }
  }
}

.b {
  border: var(--border-primary-thin);
}

.b-right {
  border-right: var(--border-primary-thin);
}

.b-left {
  border-left: var(--border-primary-thin);
}

.b-top {
  border-top: var(--border-primary-thin);
}

.b-bottom {
  border-bottom: var(--border-primary-thin);
}

.br-0 {
  border-radius: 0 !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(131, 131, 131);
  border-radius: calc(15px / 2);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(184, 184, 184);
}

.pz-card {
  background: var(--card-primary);
  border-radius: 0.5rem;
  margin: var(--margin-medium);
  overflow: hidden;
  color: var(--text-primary);
  .pz-card-header,
  .pz-card-footer {
    padding: var(--padding-medium);
    background: var(--card-header-primary);
    font-weight: 700;
  }

  .pz-card-header {
    border-bottom: var(--border-primary-thin);
  }
  .pz-card-footer {
    border-top: var(--border-primary-thin);
  }
}

.my-table {
  width: 100%;

  thead > tr > th {
    background: var(--table-header-dark);
  }

  thead > tr > th,
  tbody > tr > td {
    border: var(--border-primary-thin);
    padding: 0.5rem;
  }
}

.my-icon-btn {
  border: none;
  background: transparent;
  color: var(--text-primary);
  height: 2.5rem;
  width: 2.5rem;
  transition: 0.3s;
  border-radius: 1rem;

  &:hover {
    background: var(--btn-hover-priamry);
  }
}

.cell-input {
  width: 100%;
  background: var(--bg-input-primary);
  border: var(--border-light-thin);
  border-radius: 0.35rem;
  padding: 0.35rem;
}

.inpage-nav {
  display: flex;
  background: var(--table-header-dark);
  justify-content: space-between;
  button.nav-btn {
    background: transparent;
    padding: 1rem 1.5rem;
    border: none;
    font-weight: 700;
    font-size: 1.2rem;
    transition: 0.3s ease-in;
    &:first-child {
      border-right: var(--border-primary-thin);
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
    &:last-child {
      border-left: var(--border-primary-thin);
      border-right: var(--border-primary-thin);
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    &.active {
      background: var(--btn-active-dark);
    }

    &:hover {
      background: var(--btn-active);
    }
  }
}

.light-label {
  color: gray;
  font-weight: 600;
  font-style: italic;
  margin: 0;
}
