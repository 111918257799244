.dashboard-container {
  height: calc(100vh - (var(--height-header) + var(--height-footer)));
  color: var(--text-primary);
}
// .detail-grid {
//   display: grid;
//   grid-template-columns: 1fr;
//   background: #3a3a3a;

//   div {
//     align-self: stretch;
//   }
// }

// .data-container {
//   background: #3a3a3a;
//   box-shadow: -2px 0px 3px #11111183;
//   .data-head {
//     background: #171717;
//     text-align: center;
//     font-weight: 600;
//     padding: 1rem 0;
//     font-size: 1.3rem;
//     font-style: italic;
//     color: var(--text-secondary);
//   }
//   .data-body {
//     height: calc(100vh - 20vh);
//     overflow-y: auto;
//   }
// }

.first-half {
  height: 100%;
  display: flex;

  .col-card {
    border-radius: 0.5rem;
    box-shadow: 2px 2px 5px #272727d8;
    background: #383838;
    height: 100%;
    margin: 0.5rem 0;
  }
  .d-col-1 {
    width: 33%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  .d-col-2 {
    width: 44%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  .d-col-3 {
    display: flex;
    flex-direction: column;
    width: 33%;
    padding: 1rem;
  }
}

.data-tabs {
  display: flex;
  width: 100%;
  height: 100%;

  .tab {
    flex-grow: 1;
    .tb-head,
    .tb-body {
      padding: 0.5rem 0;
      text-align: center;
      font-style: italic;
      font-weight: 600;
    }

    .tb-body {
      font-size: 1.5rem;
      color: white;
    }

    .tb-head {
      color: rgb(172, 172, 172);
      font-style: italic;
      // border-bottom: thin solid rgba(26, 26, 26, 0.712);
    }

    &:not(:last-child) {
      border-right: thin solid #171717;
    }
  }
}
