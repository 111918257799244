.cont {
  height: calc(100vh - 13vh);
  .p-multiselect {
    display: inline-flex;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    width: 200px;
    .p-multiselect-label {
      padding: 0.5rem 0.75rem;
      transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
      color: var(--color-light);
      background: var(--bg-primary);
    }
    .p-multiselect-trigger {
      background: var(--bg-primary);
      color: var(--color-light);
    }
  }
}
.multi_select_bx {
  margin: 0px 10px;
  .p-multiselect {
    background: none !important;
    border: none !important;
    border-radius: 0 !important;
  }
  .p-multiselect .p-multiselect-label {
    padding: 12px !important;
  }
}
.filter_row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  overflow: auto;
}
.input_block {
  min-width: 180px;
}
.no_data {
  display: flex;
  justify-content: center;
  font-size: 20px;
  padding: 10px;
}
.sidebar {
  width: 25rem;
}
.subheader {
  display: flex;
  align-items: center;
  margin: 1rem;
  width: 100%;
  overflow: auto;
}
.table-class {
  position: relative !important;
  min-width: min-content !important;
  .p-datatable-wrapper table {
    width: auto !important;
    min-width: 100%;
  }
  .p-datatable-thead > tr > th {
    color: var(--color-light) !important;
    background: var(--table-header-dark) !important;
  }
  .p-datatable-tbody > tr > td {
    background: var(--table-body);
    color: var(--color-light) !important;
    font-weight: bolder !important;
  }
  .p-datatable-tfoot > tr > td {
    color: var(--color-light) !important;
    background: var(--table-header-dark) !important;
  }
  .p-datatable-header {
    background: #464646 !important;
    padding: 1rem 1rem !important;
    // width: fit-content;
    margin: 10px;
  }
  .p-paginator {
    color: var(--color-light) !important;
    background: var(--table-header-dark) !important;
    .p-paginator-current {
      background-color: var(--dialog-header-footer);
      color: var(--color-light);
    }
    .p-paginator-pages .p-paginator-page.p-highlight {
      background: var(--table-header-dark);
      color: var(--color-light);
      .p-paginator-page {
        background-color: var(--color-light);
        color: var(--btn-dark);
      }
    }
  }
}
.pool_payout_dailog {
  width: 30vw;
  .table-class .p-datatable-thead > tr > th,
  .table-class .p-datatable-thead > tr,
  th,
  td {
    background: #2a2a2a !important;
    color: #fff !important;
    border-width: 1px 0 1px 0 !important;
  }
  .footer_btn {
    border-radius: 5px !important;
  }
}
.cont .p-inputtext {
  color: var(--text-note);
  background: var(--bg-primary);
  border: thin solid var(--border-primary-thick);
}
.sele-text {
  color: var(--color-blue);
  width: 100% !important;
  display: inline-block;
  transition: 0.3s;

  &:hover {
    color: var(--color-ashva-title);
    cursor: pointer;
  }
}

.dialog-class {
  .p-dialog-header {
    background: var(--dialog-header-footer) !important;
    color: var(--color-light) !important;
    .p-dialog-header-icon {
      color: var(--color-light) !important;
    }
  }
  .p-dialog-content {
    background: var(--dialog-content) !important;
    padding: 1rem !important;
  }
  .p-dialog-footer {
    background: var(--dialog-header-footer) !important;
    padding: 0.5rem !important;
  }
}

.p-multiselect-header {
  padding: 0.75rem 1.5rem;
  background: #353535 !important;
  // max-width: fit-content !important;
}
.p-multiselect-items {
  padding: 0.5rem 0;
  background: #595959 !important;
  .p-multiselect-item {
    color: white !important; // change white to variable
  }
}
.p-multiselect-items :hover {
  background: #6e6e6e !important;
}
.calendercss {
  .p-inputtext {
    flex: 1 1 auto;
    width: 1%;
    padding: 13px;
  }
}
.multiselscroll {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1500px) {
  .table-scroll {
    height: calc(100vh - 40vh);
    overflow: auto;
  }
}
@media screen and (max-width: 1275px) {
  .multiselscroll {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 2000px) {
  .tclass {
    margin-top: 5px;
  }
}
