.head {
  background: var(--bg-primary-header);
}
.clp {
  width: 100%;
}

.side-nav {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  padding: var(--padding-medium);
  .nav-btn {
    width: 100%;
    margin-bottom: var(--margin-medium);
    padding: 1rem;
    border-radius: var(--radius-35);
    box-shadow: 0 0 14px 4px #36363698;
    border: none;
    background: #303030;
    color: var(--text-primary);
    transition: 0.3s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;

    &:hover {
      background: var(--btn-hover-priamry);
    }

    &.active {
      background: var(--btn-active-dark);
    }
  }
}

header.page-head {
  background: var(--bg-primary-header);
  padding: var(--padding-regular);
  font-size: 1.2rem;
  font-weight: 600;
}
.add-profile-btn {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 98;

  button {
    background: var(--btn-primary);
    border-radius: 50%;
    width: 4.5rem;
    height: 4.5rem;
    font-weight: 700;
    overflow: hidden;
    transition: 0.35s ease-in-out;
    white-space: nowrap;
    border: none;
    box-shadow: 0px 2px 10px 0 #0000332b;
    // box-shadow: 0 0 10px #2e0b2e9f, inset 0 0 2px #2e0b2e9f;
    &:hover {
      background: #67068dc5;
    }
  }
}

.inline-height {
  height: calc(100vh - 12.5vh);
  overflow-y: auto;
}
