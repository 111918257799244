.connectorCard {
  background: #525252 !important;
  color: #36383a !important;
  box-shadow: 0 5px 5px 1px, 0 1px 1px 0, 0 1px 3px 0 !important;
  margin: 10px !important;

  .p-card-body {
    background: #464646;
    color: var(--text-primary);
    padding: 1.5rem;
  }
}
.span {
  font-size: 1rem;
  font-weight: 700;
  font-style: italic;
  color: rgb(126, 126, 126);
}
.del {
  background: var(--color-red);
  color: var(--text-primary);
  border-radius: var(--radius-50);
  border: none;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 0 6px rgba(41, 39, 46, 0.685);
  transition: 0.3s;

  &:hover {
    background: rgb(240, 69, 69);
    color: var(--text-primary);
    box-shadow: 0 0 6px 8px rgba(41, 39, 46, 0.685);
  }
}

.card-container {
  background: #454544b4;
  border-radius: var(--radius-50);
  padding: var(--padding-medium);
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  overflow-y: auto;
  max-height: calc(100vh - 17vh);
  @media screen and(min-width:900px) and (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and(min-width:0px) and (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.cardConnector {
  background: var(--card-primary);
  border-radius: var(--radius-50);
  padding: var(--padding-big);
  .label {
    font-size: 0.85rem;
    font-weight: 700;
    font-style: italic;
    color: rgb(126, 126, 126);
  }

  header,
  section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: var(--border-primary-thin);
    .span {
      .spanLabel {
        font-size: 1.5rem !important;
        color: rgb(202, 202, 202);
        font-weight: 700;
        text-align: center;
      }
    }
  }
  section {
    padding: 1.5rem 0 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: none;

    .span .spanLabel {
      font-size: 1.5rem;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    padding: var(--padding-medium) var(--padding-regular);
    border-top: var(--border-primary-thin);
    align-items: center;

    button.del {
      background: var(--color-red);
      color: var(--text-primary);
      border-radius: var(--radius-50);
      border: none;
      padding: 0.5rem 0.75rem;
      box-shadow: 0 0 6px rgba(41, 39, 46, 0.685);
      transition: 0.3s;

      &:hover {
        background: rgb(240, 69, 69);
        color: var(--color-light);
        box-shadow: 0 0 6px 8px rgba(41, 39, 46, 0.685);
      }
    }
  }
}
.cname {
  font-size: 1.2rem;
  padding: 0 1rem;
  font-weight: 600;
  color: var(--text-secondary);
}
.refresh {
  background: var(--text-contrast);
  color: var(--color-light);
  border-radius: var(--radius-50);
  border: none;
  padding: 0.3rem 0.95rem;
  box-shadow: 0 0 6px rgba(41, 39, 46, 0.685);
  transition: 0.3s;
}
