.bf-container {
  position: fixed;
  top: 3.75rem;
  right: 0.5rem;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);

  .bf-head {
    box-shadow: -2px 0px 3px #11111183;
    background: #464646;

    font-weight: 600;
    // width: 182px;
    border-radius: 2.5rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
    font-size: 1.25rem;
    font-style: italic;
    color: var(--text-secondary);
    transition: 0.3s;
    border-left: 1px solid rgb(39, 37, 37);
    border-top: 1px solid rgb(37, 32, 32);
    border-bottom: 1px solid rgb(44, 40, 40);
    border-right: 1px solid rgb(27, 23, 23);

    .globe-icon {
      font-size: 2.5rem;
    }
    &:hover {
      background: #582864;
    }
  }

  .bf-body {
    position: fixed;
    overflow-y: auto;
    width: 30rem;
    right: 0;
    background: #323232;
    z-index: -1;
    transition: all 0.3s ease-in-out;
    &.open {
      bottom: 3rem;
      top: 3.75rem;
      box-shadow: -2px 10px 12px 3px #1f1f1fc9;

      opacity: 1;
    }

    &.close {
      bottom: 3rem;
      top: 100%;
      opacity: 0;
    }
  }
}

.bf-container-active {
  right: 17rem;

  .bf-head {
    background: rgb(35, 35, 35);
    border: none;
    border-right: 1px solid #da94f554;
  }
}

.bf-tab-container {
  .bf-tab-header {
    display: flex;
    button.bf-tab {
      padding: 0.5rem 0;
      font-weight: 600;
      font-style: italic;
      font-size: 1.15rem;
      width: 100%;
      border: none;
      box-shadow: 0 3px 5px #353535;
      background: #1d1d1d;
      transition: 0.3s ease-in-out;
      color: var(--text-primary);
      &:not(:last-child) {
        border-right: var(--border-primary-thin);
      }
      &:hover {
        background: var(--btn-hover-priamry);
      }
      &.active {
        background: var(--btn-active);
      }
    }
  }
  .bf-tab-body {
    padding: 0rem 1rem;
  }
}

.bf-info-body {
  margin: 1rem 0;
  border-radius: 0.5rem;
  border: thin solid #878787;
  overflow: hidden;
  box-shadow: 2px 3px 5px #353030;

  &:last-child {
    margin-bottom: 7.5rem;
  }

  .bf-info-all {
    background: #3c3c3c;
    padding: 0.5rem;
  }
  .bf-info-single {
    background: #5a5a5a;
    border-top: thin solid rgb(63, 63, 63);
    border-bottom: thin solid rgb(66, 66, 66);
    padding: 0.5rem;
  }
}

.note {
  text-align: center;
  background: #201e1e;
  padding: 0.35rem 0;
}
