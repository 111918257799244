.add-profile-btn-connector {
  position: absolute;
  bottom: 5.5rem;
  right: 2rem;
  z-index: 98;
  color: white;
  button {
    background: var(--btn-primary);
    border-radius: 50%;
    width: 4.5rem;
    height: 4.5rem;
    font-weight: 700;
    overflow: hidden;
    transition: 0.35s ease-in-out;
    white-space: nowrap;
    border: none;
    // box-shadow: 0 0 10px #2e0b2e9f, inset 0 0 2px #2e0b2e9f;
    box-shadow: 0px 2px 10px 0 #0000332b;
    &:hover {
      background: #67068dc5;
      color: white;
    }
  }
}
